import React, { useState } from 'react'
import { useLocale } from '../../hooks/useLocale'
import { SearchInput } from '@hrk/huw-module-library'
import { useTranslations } from '../../hooks/useTranslations'
import { localizedSlug } from '../../utils/localizedSlug'
import { navigate } from 'gatsby'
import { useQueryParam } from '../../hooks/useQueryParam'

export const SearchInputWrapper: React.FC = () => {
  // Search variables track the current search settings
  const [searchTerm, setSearchTerm] = useState('')
  const { pageLocale } = useLocale()
  const { search: searchTranslations } = useTranslations()
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_t, setQueryCategory] = useQueryParam('t', '')
  const [_f, setQueryFilterValue] = useQueryParam('f', '')
  const [_q, setQueryTerm] = useQueryParam('q', '')
  const [_p, setQueryPage] = useQueryParam('p', '1')
  const [_s, setQueryPerPage] = useQueryParam('s', '15')
  const [_a, setQuerySort] = useQueryParam('a', '')
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const handleTermChanged = (term: string) => {
    setSearchTerm(term)
  }

  const handleSearchButtonClick = () => {
    const searchParams = {
      ...{ ...(searchTerm ? { q: searchTerm } : null) },
      s: '15',
      p: '1',
    }
    const newParams = new URLSearchParams([...Object.entries<string>(searchParams)]).toString()
    navigate(`${localizedSlug(pageLocale, '/suche')}?${newParams}`)
  }

  return (
    <SearchInput
      __component={'SearchInput'}
      term={searchTerm}
      onTermChanged={handleTermChanged}
      onSearchButtonClick={handleSearchButtonClick}
      onTermErased={() => setSearchTerm('')}
      translations={{
        search: searchTranslations.inputTranslations.search,
      }}
    />
  )
}
